<template>
  <el-form
    ref="sellerForm"
    label-position="left">
    <template v-for="(item,index) in columns">
      <el-form-item :label="item.label" :prop="item.prop">
        <span v-if="item.type === 'input' || item.type === 'textarea' || item.type === 'date'">{{ item.value }}</span>
        <span v-if="item.type === 'province'">{{ province().province }}</span>
        <span v-if="item.type === 'city'">{{ province().city }}</span>
        <span v-if="item.type === 'select'">{{ selectFun(item) }}</span>
        <span v-if="item.type === 'tag'">{{ item.value[item.name] }}</span>
        <span v-if="item.type === 'arrStr'">{{ item.value.map(x => x.name).join(',') }}</span>
        <div class="file-text" v-if="item.type === 'file'">
          <p>{{ item.value }}</p>
        </div>
        <div class="file-img" v-if="item.type === 'img' && item.value">
          <img :src="item" alt="" v-for="(item,index) in item.value.split(',')" />
        </div>
        <div v-if="item.type === 'info'">
          <p>性别: {{ item.value.sex === 2? '女': '男' }}</p>
          <p>出生日期: {{ item.value.birthday }}</p>
          <p>总课节数: {{ item.value.total_course_num }}</p>
          <p>剩余课节数: {{ item.value.over_course_num }}</p>
        </div>
      </el-form-item>
      <div v-if="item.type === 'arr'" class="form-list">
        <div class="form-list-item">
          <table border="0">
            <tr>
              <th>账号</th>
              <th>联系人</th>
              <th>职位</th>
            </tr>
            <tr v-for="(item2,index2) in item.value" :key="index2">
              <td>{{ item2.username }}</td>
              <td>{{ item2.name }}</td>
              <td>{{ item2.position }}</td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </el-form>
</template>

<script>
export default {
  name: 'formSee',
  data() {
    return {}
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    selectFun(e) {
      let name = ''
      e.options.forEach(item => {
        if (item.value === e.value) {
          name = item.label
        }
      })
      return name
    },
    province() {
      let value = ''
      let city = ''
      this.columns.forEach(item => {
        if(item.type === 'province') value = item.value
        if(item.type === 'city') city = item.value
      })
      let name = ''
      let cityName = ''
      this.$store.state.address.forEach(item => {
        if (item.id === value) {
          name = item.name
          item.city.forEach(item2 => {
            if (item2.id === city) {
              cityName = item2.name
            }
          })
        }
      })
      return {
        province: name,
        city: cityName
      }
    }
  }
}
</script>

<style scoped>
.file-text{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.form-list-item table{
    width: 100%;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
}
.form-list-item th,.form-list-item td{
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    padding: 5px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.file-img{
    display: flex;
    flex-wrap: wrap;
}
.file-img img{
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>
